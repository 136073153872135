














import { defineComponent } from "@vue/composition-api";
import { useAuth } from "@/api_connectors/auth";
import RequestSentToast from "@/components/Toast/RequestSentToast.vue";
import { useToast } from "@/composables/useToast";

export default defineComponent({
    name: "ResendVerification",
    components: { RequestSentToast },
    setup() {
        const {resendVerification} = useAuth()
        const {showToast} = useToast()

        return {
            resendVerification,
            showToast
        }
    }
})
